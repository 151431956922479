<template src='./UploadFiles.html'>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./UploadFiles.css">
</style>

<script>
import Dialog from 'primevue/dialog';
import { BFormInvalidFeedback } from 'bootstrap-vue';
import  Register  from '../../../service/Register';

// ,VerifyRegister,
//


export default {
    name:'ModelFileRegister',
    components:
    {
        Dialog, BFormInvalidFeedback
    },
    data()
    {
        return{
            dni:'',
            modalform : false,
            filesDataAutorization:'',
            filesRemunerationRequest:'',

            loading:false,
            //SERVICIO
            registerSer:null,
        }


    },
    created: function ()
    {
        this.registerSer = Register();
    },
    methods:
    {
        resetValues()
        {
            this.modalform = false;
            this.filesDataAutorization='';
            this.filesRemunerationRequest='';
        },
        open_modal(dni)
        {
            this.resetValues();
            this.dni = dni;

            this.registerSer.VerifyRegister(this.dni)
                .then((sus)=>{
                    console.info(sus);
                     this.modalform = true;
                })
                .catch(err => {
                    console.error(err);
                    this.modalform = false;
                    this.dni = '';
                })
        },
        get_solicitud_remuneracion()
        {
            this.registerSer.getSolicitudRemuneracion(this.dni);

        },
        get_autorizacion_data()
        {
            this.registerSer.getAutorizacionData(this.dni)

        },
        uploadFile(nombreFile)
        {
            if (nombreFile.localeCompare('solicitud_remuneracion')==0)
            {
                if (!this.$refs.solicitud_remuneracion.files)
                {
                    return;
                }
                this.filesRemunerationRequest = this.$refs.solicitud_remuneracion.files[0];
                return;
            }
            if (nombreFile.localeCompare('autorizacion_data')==0)
            {
                if (!this.$refs.autorizacion_data.files)
                {
                    return;
                }
                this.filesDataAutorization = this.$refs.autorizacion_data.files[0];
                return;
            }

        },
        uploadFileSubmit()
        {
            if (this.filesRemunerationRequest && this.filesDataAutorization)
            {
                // console.log('ok con los archivos');
                let formData = new FormData();
                formData.append("solicitud_remuneracion",this.filesRemunerationRequest);
                formData.append("autorizacion_data",this.filesDataAutorization);
                this.filesDataAutorization ='';
                this.filesRemunerationRequest = '';

                this.registerSer.uploadFilesRegister(this.dni,formData)
                .then(sus => {
                    // console.log(sus);
                    this.modalform = false;
                    alert("subida con exito");
                    this.updateTable();
                })
                .catch(err =>
                {
                    console.error(err)
                    this.modalform = false;
                });


                return;
            }
            console.error('mecmec le faltan archvivos');
        },
        updateTable()
        {
            this.$emit('uploadOK');
        }

    },
    props: {
        dnix: String
    }
}
</script>
