<template src='./Delete.html'>
</template>


<script>
import Dialog from 'primevue/dialog';
import { BFormInvalidFeedback } from 'bootstrap-vue';
import Register from '../../../service/Register'



export default {

   components:
    {
        Dialog, BFormInvalidFeedback
    },
    created:function () {
      this.$moment.locale('es');
      this.registerSer = Register();
    },
    data()
    {
      return{
            selected:null,
            modalform:false,
            model:{},
        }
    },
    methods:{
      openModal(item)
      {
        this.selected = item;
        this.model = Object.assign({}, item);
        this.modalform = true;
      },
      onDelete()
      {
        // console.log('olenka')
        this.registerSer.delete(this.selected.dni).then()
        {
          this.modalform = false;
          this.$toast.add({severity:'success', summary:"Retirado de los activos", life: 3000});
          this.reload();
        }



        //// console.log('borrando')
        // this.service.delete(this.selected.id).then ()
        // {
        //   this.modalform = false;
        //   this.$toast.add({severity:'success', summary:"Retirado de los activos", life: 3000});
        //   this.reload();
        // }
      },

      reload()
      {
        this.$emit('reset');
      }

    }


}

</script>
