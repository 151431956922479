<template src='./AdminAppUsers.html'>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./AdminAppUsers.css">
</style>

<script>
import  Register  from '../../service/Register';
import ModelFileRegisterComponent from './UploadFiles/UploadFiles';
import DeleteComponent from './Delete/Delete'


export default {

    data(){
        return {
            name:"pochito",
            //este arreglo solo muestra lo que está en la tabla
            users:[],
            //se han seleccionado para aprobar?
            user_approved: [],
            //usuarios seleccionados para descargar archivos
            user_download_files:[],
            //DNI seleccionado de una fila de la tabla
            dni:'',

            //SERVICIO donde se sacan los datos
            registerSer:null,



            filters: {
              page:1,
              perPage: 10,
              totalRows: 0
            },
            totalRows: 0,
            service:null,
            data:[]

        }
    },
    created: function () {
        this.registerSer = Register();
        this.initTable();

    },
    methods:
    {
        clearFilters() {
            const omit  = ['perPage', 'totalRows', 'page'];
            Object.keys(this.filters).map(((value) => { if( omit.indexOf(value) === -1){ this.filters[value] = null} }));
            this.initTable();
        },
        //Inserta los valores iniciales de la table, según el filtro
        initTable()
        {
          this.registerSer.getUserWitoutDocuments (this.filters)
          .then((temp)=>
          {

               console.log('llego',temp)
              this.users = temp.data.data;
              this.filters.totalRows = temp.data.total;

              // console.log('seteo',this.users);
          });
        },
        onPage(event){
                this.filters.page  = event.page + 1;
                this.initTable();
            },
        //abre el un modal para insertar los documentos
        uploadFiles(dni)
        {
            this.name = dni;
            this.dni = dni;
            // console.log('el dni que entró es',dni);
            this.$refs.modalRegister.open_modal(dni);
        },
        //la respuesta del modal si está la subida de documentos ok
        uploadFilesOK()
        {
          this.initTable();
        },
        //la aprobación masiva de usuarios para la aplicación
        aceptar_usuarios()
        {
            this.registerSer.authorizeMultiplesUsers(this.user_approved).
            then(upd => {
                console.log(upd);
                this.initTable();
                // this.updateSelected();
                // alert('se aprobaron con exito.')
            })
            .catch(err => console.error(err))
        },
        //la descarga masiva de los documentos de las usuarios
        downloadMuliplesFiles()
        {
            this.user_download_files.forEach(dni=>{
                this.registerSer.getSolicitudRemuneracion(dni);
                this.registerSer.getAutorizacionData(dni);
            })
        },

        OnDelete(item){
          this.$refs.Delete.openModal(item);
        }
    },
    computed: {
        selectAll: {
            get: function () {
                return this.users ? (this.user_approved.length == this.users.filter(us => us.term_cond==='0').length ) && (this.users.filter(us => us.term_cond==='0').length >0): false;
            },
            set: function (value) {

                var selected = [];

                if (value) {
                    this.users.filter(us => us.term_cond==='0').forEach(function (user) {
                        selected.push(user.dni);
                    });
                }
                this.user_approved = selected;
            }
        },
        DownloadFilesAll:
        {
            get: function ()
            {
                return this.users ?
                    (this.user_download_files.length == this.users.length) && (this.users.length>0)
                    : false;
            },
            set: function (value) {

                var selected = [];

                if (value) {
                    this.users.forEach(function (user) {
                        selected.push(user.dni);
                    });
                }
                this.user_download_files = selected;
            }
        }
    },

    props: {
        msg: String
    },
    components: {
        ModelFileRegisterComponent,
        DeleteComponent
    }
};

</script>




