import axios from 'axios'
import env from  '../envioments'


export class Register
{

    uploadFilesRegister (dni_upload,formData)
    {
        return axios.post( `${env.remoteUrl}/admin/register_users/${dni_upload}/uploadfiles`,
        formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
    }

    VerifyRegister (dni_upload)
    {
        return axios.get( `${env.remoteUrl}/admin/register_users/${dni_upload}/verificacion`)
    }


    getSolicitudRemuneracion(dni_upload)
    {
        return axios.get( `${env.remoteUrl}/admin/register_users/${dni_upload}/solicitud_remuneracion_by_user`, {responseType: 'arraybuffer'})
        .then(response => {
            let blob = new Blob([response.data], { type: 'application/pdf' })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'solicitud_remuneracion_'+dni_upload+'.pdf'
            link.click()
        })

    }

    getAutorizacionData(dni_upload)
    {
        return axios.get(`${env.remoteUrl}/admin/register_users/${dni_upload}/autorizacion_datos_by_user`, {responseType: 'arraybuffer'})
        .then(response => {
            let blob = new Blob([response.data], { type: 'application/pdf' })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'autorizacion_data_'+dni_upload+'.pdf'
            link.click()
        })

    }
    getUserWitoutDocuments(filter = {})
    {
        return axios.get(`${env.remoteUrl}/admin/register_users/sindocumentos`, {params: filter})
    }

    authorizeMultiplesUsers(users)
    {
        return axios.post( `${env.remoteUrl}/admin/register_users/authorizeMultiplesUsers`,
            {users:users}
        )
    }

    delete (dni)
    {

      return axios.delete(`${env.remoteUrl}/admin/register_users/${dni}`,{})

    }

}


export default () => {
    return new Register()
};


// export {uploadFilesRegister,VerifyRegister,
//     getSolicitudRemuneracion,getAutorizacionData,
//     getUserWitoutDocuments,authorizeMultiplesUsers};

// export class Regiter {
//     // module;
//     // constructor(module) {
//     //     this.module = module;
//     // }

//     uploadFiles (dni_upload,formData)
//     {
//         return axios.post( `${env.remoteUrl}/register/${dni_upload}/uploadfiles`,
//         formData,
//             {
//                 headers: {
//                     'Content-Type': 'multipart/form-data'
//                 }
//             }
//         )
//     }
// }

// export default () => {
//     return new Regiter();
// }

